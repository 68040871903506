import React from "react"
import { Link } from "gatsby"
import { css } from "styled-components"
import { transformURLs } from "./utils/transformURLs"

const TabContent = props => {
  const GeneratedPagesList = () => {
    return (
      <>
        <div
          css={css`
            .tabs {
              overflow: hidden;
              text-align: left;
            }
            .tab {
              position: relative;
              width: 100%;
              color: var(--white);
              overflow: hidden;
              &-label {
                display: flex;
                justify-content: space-between;
                padding: 1em 0;
                background: var(--darkgrey);
                color: var(--white);
                cursor: pointer;
                border-bottom: 1px solid var(--white);
                font-size: 1.2rem;
                &:hover {
                  color: var(--green);
                }

                /* Icon */
                &::after {
                  content: "\\276F";
                  width: 1em;
                  height: 1em;
                  text-align: center;
                  transition: all 0.35s;
                  color: var(--white);
                }
              }
              &-content {
                max-height: 0;
                color: var(--green);
                transition: all 0.35s;
                overflow: hidden;
              }
              input {
                position: absolute;
                width: 100%;
                height: 70px;
                opacity: 0;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            input:checked {
              + .tab-label {
                color: var(--green);
                border-bottom: 1px solid var(--green);
                &::after {
                  transform: rotate(90deg);
                  color: var(--green);
                }
              }
              ~ .tab-content {
                max-height: 150px;
                padding: 1em 0;
                overflow-y: auto;
              }
            }
            .page-generator-pro-related-links {
              display: inline-block;
              list-style: none;
              column-count: 2;
              padding-left: 0;
              @media (min-width: 992px) {
                display: block;
                column-count: 4;
                column-gap: 0;
              }
              li:first-of-type {
                margin: 0 0 1rem 0;
              }
              a {
                font-size: clamp(0.7rem, 0.5177rem + 0.778vw, 1rem);
                &:hover,
                &:active {
                  color: var(--green);
                }
              }
            }
            .tab-content ul {
              @media (min-width: 992px) {
                column-count: 2;
              }
            }
            .tab-content li:first-of-type {
              @media (min-width: 992px) {
                margin-top: 0;
              }
            }
            a {
              text-transform: none;
              font-size: 1em;
            }
          `}
          dangerouslySetInnerHTML={{
            __html: transformURLs(props.tabContent.seoComponentContent),
          }}
        />
      </>
    )
  }

  const CategoryPages = () => {
    const categoryData = props.data.categories.categories.edges
    const categoryPages2 = categoryData.filter(
      category =>
        category.node.name !== null &&
        category.node.name === props.tabContent.seoComponentPageCategory.name
    )

    return (
      <div
        css={css`
          @media (min-width: 992px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
          }
          a {
            text-transform: none;
          }
        `}
      >
        <ul
          css={css`
            margin-top: 1em;
            display: grid;
            grid-template-columns: repeat(2, 50%);
            list-style: none;

            @media (min-width: 992px) {
              grid-template-columns: repeat(4, auto);
              grid-column-gap: 1em;
              margin-top: 2em;
            }
          `}
        >
          {categoryPages2[0].node.pages.nodes.map((categoryPages, key) => {
            return (
              <li
                css={css`
                  display: inline-block;

                  margin: 0 0 1rem 0;
                `}
                key={key}
              >
                <Link
                  css={css`
                    font-size: 1em;
                    &:hover,
                    &:active {
                      color: var(--green);
                    }
                  `}
                  to={categoryPages.uri}
                >
                  {categoryPages.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const contentType =
    props.tabContent !== null
      ? props.tabContent.seoComponentTypeOfContent
      : null

  const typeOfContent = () => {
    switch (contentType) {
      case "generated":
        return <GeneratedPagesList />
      case "both":
        return (
          <>
            <CategoryPages />
            <GeneratedPagesList />
          </>
        )
      case "static":
        return <CategoryPages />
    }
  }
  return <>{typeOfContent()};</>
}
export { TabContent }
